<template>
    <div class="products-page">
        <div class="container-fluid">
            <h1>Produkty</h1>
            <div class="d-flex justify-content-between mt-3">
                <b-pagination v-if="totalRows>perPage" 
                    v-model="currentPage"
                    :totalRows="totalRows"
                    :per-page="perPage"
                ></b-pagination>
            </div>
            <b-table :items="myDataProvider" :fields="fields" :filter="filter" :show-empty="true"
                    class="mt-2" striped  outlined sort-icon-left empty-filtered-text="Nie znaleziono" empty-text="Brak danych"
                    :current-page="currentPage" :per-page="perPage"
                    sort-by="id"
                    :sort-desc="true"
                >
            </b-table>
            
        </div>
    </div>
</template>

<script>
import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

export default {
    name: 'adminProducts',
    data() {
        return {
            isLoading: false,
            filter: [],
            perPage: 25,
            currentPage: 1,
            totalRows: 0,
            fields: [
                { label: 'Id', key: 'id', sortable: true },
                { label: 'Nazwa', key: 'name', sortable: true },
                { label: 'Grupa', key: 'group_name', sortable: true },
                { label: 'Cena', key: 'price', sortable: true },
                { label: 'Waga', key: 'weight', sortable: true },
                { label: 'Status', key: 'active', sortable: true },
                { label: 'Poniedziałek', key: 'monday_avilible', sortable: true },
                { label: 'Wtorek', key: 'tuesday_avilible', sortable: true },
                { label: 'Środa', key: 'wednesday_avilible', sortable: true },
                { label: 'Czwartek', key: 'thursday_avilible', sortable: true },
                { label: 'Piątek', key: 'friday_avilible', sortable: true },
                { label: 'Sobota', key: 'saturday_avilible', sortable: true },
                { label: 'Niedziela', key: 'sunday_avilible', sortable: true }
            ]
        }
    },
    computed: {
    },
    methods: {
        myDataProvider(ctx) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            const promise = axios.post('/admin/products', {
                "perPage": ctx.perPage,
                "currentPage": ctx.currentPage,
                "sortBy": ctx.sortBy,
                "asc": ctx.sortDesc ? false : true,
                "filter": []
            });

            return promise.then(result => {
                const items = result.data.routes;
                this.totalRows = result.data.count || 0;
                return items || []
            })
        }
    }
}
</script>
